/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:16:24 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-11-We 09:51:12
 */
<template>
  <div class="myCourse">
    <div class="personal_top">
      <span
        v-for="(lt, i) in list_top"
        :key="i"
        :class="listTopIndex == lt.id ? 'active' : ''"
        @click="listTopClick(lt)"
        >{{ lt.name }}</span
      >
    </div>
    <ul class="cont" v-if="list.length > 0">
      <li v-for="(li, i) in list" :key="i" @click="playClick(li)">
        <div class="li_left">
          <img v-lazy="li.CoverImg" alt="" />
          <div class="del">
            <p>{{ li.CourseName }}</p>
            <p>
              有效时间：{{ li.ValidityDateStart | dataYMD }} ~
              {{ li.ValidityDateEnd | dataYMD }}
            </p>
            <p>授课教师： {{ li.TeacherName }}</p>
          </div>
        </div>
        <div class="li_right">
          <div v-if="li.CourseType == 0 && li.OpenStatus == 1">
            <el-button class="zbz">直播中</el-button>
          </div>
          <div v-else-if="li.CourseType == 0 && li.OpenStatus == 0">
            <el-button class="wks">未开始</el-button>
          </div>
          <!-- <div>
            <el-button class="jxxx">继续学习</el-button>
            <el-progress :percentage="50" :color="customColor"></el-progress>
          </div> -->
          <div v-else-if="li.CourseType == 1">
            <el-button class="ksxx">开始学习</el-button>
            <el-progress
              :percentage="li.percentage"
              :color="customColor"
            ></el-progress>
          </div>
        </div>
      </li>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </ul>
    <div class="cont" v-else>
      <no-data></no-data>
    </div>
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
  </div>
</template>

<script>
import { courseQueryUserCourse, courseQueryVideoCourse } from "@/api/personal";
import { studentCompareFace } from "@/api/api";
import camera from "@/components/canvasCamera.vue";
import noData from "@/components/noData";
export default {
  data () {
    return {
      Imghost: this.GLOBAL.hostUrl,
      list_top: [
        { name: "我的直播", id: 0 },
        { name: "我的点播", id: 1 },
        { name: "我的面授", id: 2 },
      ],
      listTopIndex: 0,
      customColor: "#FA9722",
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      courseType: 0, //点播/直播，0直播，1点播
      list: [],
      page: 1, //页码
      pageSize: 5, //每页条数
      total: 0,
      dialogVisible: false,
      play: {},
    };
  },
  components: {
    camera,
    noData,
  },
  created () { },
  mounted () {
    this.postCourseQueryUserCourse();
  },
  methods: {
    getClose () {
      this.dialogVisible = false;
    },
    //分页
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      this.page = val;
      this.postCourseQueryUserCourse();
    },
    //直播、点播跳转
    playClick (obj) {
      if (obj.CourseType == 2) return false
      // 获取当前系统时间戳
      let time = parseInt(new Date().getTime() / 1000);
      // 有效时间转时间戳
      let dateEnd = Math.round(new Date(obj.ValidityDateEnd).getTime() / 1000);
      let dateStart = Math.round(
        new Date(obj.ValidityDateStart).getTime() / 1000
      );
      if (dateEnd < time || dateStart > time) {
        this.$message.info("未在有效时间内");
        return false;
      }
      this.play.obj = obj;
      if (this.courseType == 0) {
        this.dialogVisible = true;
        this.play.route = "/liveBroadcast";
        // this.$router.push({ path: this.play.route, query: this.play.obj });
      } else {
        // //是否收费IsCharge
        // if (obj.IsCharge == true) {
        //   this.dialogVisible = true;
        //   this.play.route = "/onDemand";
        //   return false;
        // }
        this.$router.push({ path: "/onDemand", query: obj });
      }
    },
    // 人脸识别
    async postStudentCompareFace (flag) {
      console.log(flag);
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        this.$router.push({ path: this.play.route, query: this.play.obj });
      } else {
        this.$message.error("识别失败");
        this.dialogVisible = false;
      }
    },
    //切换
    listTopClick (obj) {
      this.courseType = obj.id;
      // if (obj.id == 1) {
      //   this.courseType = 0;
      // } else {
      //   this.courseType = 1;
      // }
      this.listTopIndex = obj.id;
      this.page = 1;
      this.list = [];
      this.postCourseQueryUserCourse();
    },
    //列表数据
    async postCourseQueryUserCourse () {
      let parm = {
        studentId: this.userInfo.Id,
        courseType: this.courseType,
        pageIndex: this.page,
        pageSize: this.pageSize,
      };
      const res =
        this.courseType == 1
          ? await courseQueryVideoCourse(parm)
          : await courseQueryUserCourse(parm);

      if (res.success == true) {
        this.list = res.response.data;
        this.list.forEach((item) => {
          let per = isFinite(
            Math.ceil((item.StudentId / item.AllDuration) * 100)
          )
            ? Math.ceil((item.StudentId / item.AllDuration) * 100)
            : 0;
          this.$set(item, "percentage", per);
        });
        this.total = res.response.dataCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.myCourse {
  .cont {
    padding: 40px 30px;
    li {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .li_left {
        display: flex;
        img {
          width: 215px;
          height: 120px;
        }
        .del {
          margin-left: 30px;
          flex: 1;
          p {
            font-size: 16px;
            color: #666666;
            line-height: 42px;
            &:nth-child(1) {
              font-size: 20px;
              color: #333333;
              line-height: 34px;
            }
            &:nth-child(2) {
              // margin-top: 12px;
            }
          }
        }
      }
      .li_right {
        .el-button {
          width: 140px;
          height: 60px;
          font-size: 18px;
          border-radius: 10px;
          color: #ffffff;
        }
        .zbz {
          background: #ec2f53;
        }
        .wks {
          color: #7c7c7c;
          background: #d8d9d9;
        }
        .jxxx {
          background: #fa9722;
        }
        .ksxx {
          background: #1288f4;
        }
        .el-progress {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>